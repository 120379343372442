/* Sidebar */

body .sidebar .nav.sub-menu {
    .nav-item {
        &.active {
            .nav-link {
                background: none !important;
            }
        }
    }
}

.sidebar {
    min-height: calc(100vh - #{$navbar-height});
    height: 100%;
    background: $sidebar-gradient;
    font-family: $type-2;
    padding: 0;
    width: $sidebar-width-lg;
    z-index: 11;
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;

    > .nav {
        overflow: hidden;
        flex-wrap: nowrap;
        flex-direction: column;

        .nav-item {
            .collapse {
                z-index: 999;
            }

            &:not(.hover-open) {

                .collapse,
                .collapsing {
                    .sub-menu {
                        margin: 10px 0;

                        .nav-item {
                            .nav-link {
                                padding: 10px 0 10px 70px;
                                position: relative;
                                color: rgba($sidebar-light-menu-color, 0.8);
                                font-size: calc(#{$sidebar-menu-font-size} - 2px);
                                font-weight: 500;
                                transition-duration: 0.5s;
                                transition-timing-function: "ease-in-out";
                                transition-property: "background", "color", "box-shadow", "padding";

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 35px;
                                    display: block;
                                    height: 100%;
                                    width: 2px;
                                    background: $sidebar-light-submenu-line-color;
                                }

                                &:hover {
                                    color: $sidebar-light-menu-color;

                                    &:before {
                                        background: #fff;
                                    }
                                }
                            }


                            &.active {
                                .nav-link {
                                    color: $sidebar-light-menu-color;

                                    &:before {
                                        background: #fff;
                                    }
                                }
                            }

                            &:first-child {
                                .nav-link {
                                    padding-top: 0;
                                }
                            }

                            &:last-child {
                                .nav-link {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .nav-link {
                align-items: center;
                display: flex;
                padding: 15px 40px 15px 55px;
                white-space: nowrap;
                color: $sidebar-light-menu-color;
                cursor: pointer;

                i {
                    &.menu-arrow {
                        margin-left: auto;
                        margin-right: 0;
                        transition-duration: 0.2s;
                        transition-property: transform;
                        transition-timing-function: ease-in;

                        &:before {
                            content: "\F054";
                            font-family: "Material Design Icons";
                            font-size: 18px;
                            line-height: 1;
                            font-style: normal;
                            vertical-align: middle;
                            color: $sidebar-light-menu-arrow-color;
                        }
                    }
                }

                &[aria-expanded="true"] {

                    i {
                        &.menu-arrow {
                            transform: rotate(90deg);
                        }
                    }
                }

                .menu-icon {
                    display: none;
                    margin-right: 1.25rem;
                    width: $sidebar-icon-size;
                    line-height: 1;
                    font-size: 18px;
                    color: $sidebar-light-menu-icon-color;
                }

                .menu-title {
                    color: inherit;
                    display: inline-block;
                    font-size: $sidebar-menu-font-size;
                    line-height: 1;
                    vertical-align: middle;
                    font-weight: 500;
                }

                .badge {
                    margin-left: auto;
                }

                &:hover {
                    color: $sidebar-navlink-menu-item-hover-color;
                }
            }

            &.active {
                > .nav-link {
                    padding-left: 65px;
                    padding-right: 30px;
                    color: $sidebar-light-menu-active-color;

                    &:not(.bg-danger) {
                        background: $sidebar-light-menu-hover-bg !important;
                    }

                    .menu-title,
                    i {
                        color: inherit;
                    }
                }
            }

            &.nav-profile {
                max-width: 270px;
                margin-right: auto;
                margin-left: auto;
                margin-top: 30px;
                margin-bottom: 30px;

                .nav-link {
                    display: flex;
                    border-radius: 3px;
                    border: $sidebar-nav-item-profile-border-color;
                    background-color: $sidebar-nav-item-profile-bg;
                    color: $sidebar-nav-item-profile-color;
                    padding: 15px 25px;

                    .profile-image {
                        margin-right: 15px;
                        position: relative;
                    }

                    .dot-indicator {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }

                    .profile-name {
                        margin-bottom: 5px;
                        font-weight: 500;
                        font-size: 15px;
                        color: $sidebar-profile-name-color;
                    }

                    .designation {
                        margin-bottom: 0;
                        font-weight: 400;
                        color: $sidebar-profile-designation-color;
                    }
                }
            }

            &.nav-category {
                color: $sidebar-light-category-color;
                border-bottom: 1px solid $sidebar-nav-category-border-color;
                margin: 0 30px;
                font-size: 15px;
                font-weight: 500;
                padding: 10px 0 15px 0;
            }
        }

        > .nav-item {
            > .nav-link {
                min-height: $nav-link-height;
                white-space: normal;

                .menu-title {
                    line-height: 1.5;
                    word-break: break-word;
                }
            }

            .sub-menu {
                .menu-title {
                    font-size: 13px;
                    line-height: 1.5;
                    white-space: normal;
                }
            }
        }

        > .nav-item:not(.nav-profile) {
            > .nav-link {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: 30px;
                    right: 50%;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    border: 2px solid $sidebar-nav-item-dot-color;
                    display: block;
                }
            }
        }

        &:not(.sub-menu) {
            > .nav-item {
                > .nav-link {
                    background: transparent;
                    transition-duration: 0.3s;
                    transition-timing-function: "ease-in-out";
                    transition-property: "background", "color", "box-shadow", "padding";

                    &[aria-expanded="true"] {
                        background: $sidebar-light-menu-hover-bg;
                        padding-left: 65px;
                        padding-right: 30px;

                        &:before {
                            border-color: $sidebar-light-menu-hover-color;
                        }

                        .menu-title {
                            color: $sidebar-light-menu-hover-color;
                        }

                        .menu-arrow {
                            &:before {
                                color: $sidebar-light-menu-arrow-color;
                            }
                        }
                    }
                }

                &:hover {
                    &:not(.nav-profile):not(.hover-open) {
                        > .nav-link {
                            background: $sidebar-light-menu-hover-bg;
                            padding-left: 65px;
                            padding-right: 30px;

                            &:before {
                                border-color: $sidebar-light-menu-hover-color;
                            }

                            .menu-title {
                                color: $sidebar-light-menu-hover-color;
                            }

                            .menu-arrow {
                                &:before {
                                    color: $sidebar-light-menu-arrow-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* style for off-canvas menu*/

@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - #{$navbar-height});
        top: $navbar-height;
        bottom: 0;
        overflow: auto;
        right: -$sidebar-width-lg;
        transition: all 0.25s ease-out;

        &.active {
            right: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .sidebar {
        padding-top: 40px;
    }
}
