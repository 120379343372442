.nav,
.navbar-nav {
    .nav-item {
        line-height: 1;

        &.dropdown {
            @media (max-width: 992px) {
                position: unset;
            }

            .dropdown-toggle {
                &:after {
                    border: none;
                    content: "\F140";
                    font-family: "Material Design Icons";
                    font-size: 20px;
                    color: $navbar-dropdown-toggle-arrow-color;
                    text-rendering: auto;
                    line-height: inherit;
                    vertical-align: 0;
                }
            }

            .count-indicator {
                position: relative;
                text-align: center;

                i {
                    font-size: 21px;
                    margin-right: 0;
                    vertical-align: middle;
                }

                .count {
                    position: absolute;
                    left: 50%;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 100%;
                    background: $navbar-count-indicator-bg;
                    color: $white;
                    font-size: 11px;
                    top: -1px;
                    font-weight: 600;
                    line-height: 1rem;
                    border: none;
                    text-align: center;
                }

                &:after {
                    display: none;
                }
            }

            i {
                margin-right: 0.5rem;
                vertical-align: middle;

                .rtl & {
                    margin-left: 0.5rem;
                    margin-right: 0;
                }
            }

            .navbar-dropdown {
                font-size: 0.9rem;
                margin-top: 15px;
                position: absolute;
                right: 0;
                left: auto;
                border: 1px solid $navbar-dropdown-border-color;
                padding: 0 0 20px;
                min-width: 100%;
                border-radius: 2px;
                @extend .dropdownAnimation;
                box-shadow: $navbar-dropdown-box-shadow;
                overflow-x: hidden;
                max-height: 350px;
                overflow-y: auto;

                .rtl & {
                    right: auto;
                    left: 0;
                }

                overflow: hidden;

                @media (max-width: 991px) {
                    right: -85px;
                }

                &.dropdown-left {
                    left: 0;
                    right: auto;
                }

                .badge {
                    margin-left: 0.5rem;

                    .rtl & {
                        margin-left: 0;
                        margin-right: 1.25rem;
                    }

                    @media (max-width: 991px) {
                        margin-right: 0.5rem;

                        .rtl & {
                            margin-left: 0.5rem;
                            margin-right: 0;
                        }
                    }
                }

                .dropdown-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    padding: 5px 25px;
                    cursor: pointer;

                    i {
                        font-size: 24px;
                    }

                    .ellipsis {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .preview-icon {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .small-text {
                        font-size: 0.75rem;
                    }
                }

                .dropdown-divider {
                    margin: 0;
                }
            }
        }
    }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: map-get($theme-colors, "primary");
}
