.modal {
    .modal-content {
        .card {
            border: none;
            box-shadow: none;

            .card-title {
                text-transform: none;
                margin-bottom: 30px;
            }

            form {
                label {
                    display: block;
                }
            }
        }
    }

    &--fullscreen {
        .modal-dialog {
            max-width: 90%;
        }
    }

    .button-wrapper {
        margin: -1rem 0 0 0;

        > .btn {
            margin: 1rem 0 0 0;
        }
    }

    .modal-title {
        font-family: "roboto", sans-serif;
        font-weight: 500;
        color: #212229;
        font-size: 14px;
    }

    .modal-header {
        align-items: center;
        padding: 1rem 1.5rem;

        .close {
            margin: -1rem;
        }
    }

    .modal-body {
        padding: 1.5rem;
    }

    .modal-footer {
        justify-content: flex-start;
        padding: 1rem 1.5rem;
    }
}

.modal:not([data-endpoint-name="traindriver"]) {
    form {
        .form-group {
            &:last-of-type {
                margin-bottom: 30px;
            }
        }
    }
}

.loading-spinner {
    border: 4px solid map-get($theme-colors, "secondary");
    border-top: 4px solid map-get($theme-colors, "primary");
    border-radius: 50%;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
}